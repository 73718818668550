import './styles/App.css';
import {Logo, IconOpenLink, Browser} from './components/icons';

function App() {
  return (
    <div className="page">
      <header id="header">
        <Logo />
      </header>
      <div className="screen-reader-only" aria-label="Skip to section">
        <a href="#" className="skip-link">Skip to header</a>
        <a href="#content" className="skip-link">Skip to main content</a>
        <a href="#footer" className="skip-link">Skip to footer</a>
      </div>
      <p className="intro">Welcome to Moondust Collective, where imagination meets impact and creativity. Under this umbrella, we unite a constellation of innovative and purposeful ventures where at the heart is a team of talented individuals who dare to dream and experiment. Together, we are shaping a brighter and more inclusive tomorrow for everyone.</p>
      <div className="embed-content" id="content" role="main">
        <hr />
        <div className="single-embed-content">
          <div className="embed-description">
            <h3>Hello Accessible</h3>
            <a href="https://www.helloaccessible.com" target="_blank" aria-label="Link opens in a new tab" role="article">helloaccessible.com <IconOpenLink /></a>
            <p>Leading the way in inclusivity, we specialize in digital accessibility consulting to create a web that is open and welcoming to all.</p>
          </div>
          <div className="browser-container">
            <Browser />
            <embed src="https://www.helloaccessible.com" />
          </div>
        </div>
        <hr />
        <div className="single-embed-content">
          <div className="embed-description">
            <h3>Hello Moonglow</h3>
            <a href="https://www.hellomoonglow.com" target="_blank" aria-label="Link opens in a new tab" role="article">hellomoonglow.com <IconOpenLink /></a>
            <p>Transforming precious moments in life into fine art, we offer photography and videography for weddings, portraits, editorials, and more—capturing stories of love, light, and emotion.</p>
          </div>
          <div className="browser-container">
            <Browser />
            <embed src="https://www.neptu.co" />
          </div>
        </div>
        <hr />
      </div>
      <footer id="footer">
          <p>© Moondust Collective 2024 | Made with ♡ by</p>
          <a href="https://www.patriciagoh.com" target="_blank" aria-label="Link opens in a new tab">Patricia Goh <IconOpenLink /></a>
      </footer>
    </div>
  );
}

export default App;
